import _ from 'lodash'
import { AUTOFILL_MEMBER_EMAIL_ROLE } from '../../../../../constants/roles'
import { CRM_TYPES } from '../../../../../constants/crm-types-tags'

export const getToggleValue = (state, emailField: FormField) => {
  const { autofillFormInfo, config } = state

  return _.isNil(autofillFormInfo) ? !_.isNil(config?.autofillToggleValue) : autofillFormInfo
}

export const getRadioValue = (state, emailField: FormField) => {
  const { autofillFormInfoRadioValue } = state
  const radioConnection: ComponentConnection = emailField?.nonPrimaryConnections?.find(
    (connection: ComponentConnection) =>
      connection.role === AUTOFILL_MEMBER_EMAIL_ROLE &&
      !_.isNil(connection.config.isAutofillEditable),
  )

  return _.isNil(autofillFormInfoRadioValue)
    ? radioConnection?.config?.isAutofillEditable.toString()
    : autofillFormInfoRadioValue?.toString()
}

export const getFieldById = (fields: Array<FormField>, fieldCompId: string): FormField =>
  fields.find((field: FormField) => field.componentRef.id === fieldCompId)

export const getFieldWithNonPrimaryRole = (fields: Array<FormField>, role: string): FormField =>
  fields.find((field: FormField) => {
    const connection = field.nonPrimaryConnections.find(
      (connection: ComponentConnection) => connection.role === role,
    )

    return !!connection
  })

export const getEmailFields = (fields: Array<FormField>): Array<FormField> =>
  fields.filter((field: FormField) => field.crmType === CRM_TYPES.EMAIL)
